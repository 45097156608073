import { combineReducers } from 'redux'
import {reducer as formReducer} from 'redux-form'

import contactFormReducer from './contactFormReducer'

const rootReducer = combineReducers({
    contactFormState: contactFormReducer,
    form: formReducer,
})

export default rootReducer