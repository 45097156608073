import React from 'react'

class MobileMenu extends React.Component{

    render(){
        return(
            <button 
            className={`c_hamburger-icon ${this.props.open ? 'c_active' : ''}`} 
            onClick={this.props.onClick}>
                <span />
                <span />
            </button>
        )
    }
}

export default MobileMenu