import React from 'react'

class ContactList extends React.Component{

    render(){
        return(
            <ul className="c_contacts-list">
                <li>
                    <a href="mailto:info@crowntocork.co.za">E: info@crowntocork.co.za</a>
                </li>
                <li>
                    <a href="tel:021 300 0887">T: 021 300 0887</a>
                </li>
            </ul>
        )
    }
}

export default ContactList