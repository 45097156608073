import React from 'react'
import HeroBannerSlider from '../ui/HeroBannerSlider'
import Header from '../ui/Header'
import Footer from '../ui/Footer'
import Section from '../ui/Section'
import Container from '../ui/Container'
import Grid from '../ui/Grid'
import Horline from '../ui/Horline'
import GridSlider from '../ui/GridSlider'
import ContactForm from '../forms/ContactForm'
import ScrollDownButton from '../ui/ScrollDownButton'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Fade from 'react-reveal/Fade';


//images
import rid1 from '../../assets/img/rid-01@2x.jpg'
import rid2 from '../../assets/img/rid-02@2x.jpg'
import rid3 from '../../assets/img/rid-03@2x.jpg'
import dos1 from '../../assets/img/dos-01@2x.jpg'
import dos2 from '../../assets/img/dos-02@2x.jpg'
import dos3 from '../../assets/img/dos-03@2x.jpg'
import dis1 from '../../assets/img/dis-01@2x.jpg'
import dis2 from '../../assets/img/dis-02@2x.jpg'
import dis3 from '../../assets/img/dis-03@2x.jpg'

class Home extends React.Component {
    render(){
        return(
            <>
                <Header />
                <HeroBannerSlider>
                    <ScrollDownButton />
                </HeroBannerSlider>

                <Section>
                    <Container id="three-step-process">
                        <Grid>
                            <Fade delay={350}  duration={1000}>
                                <h2 className="c_section-heading">3 STEP PROCESS</h2>
                                <div className="c_copy">
                                    <p>Crown to Cork is a service provider for the ever-growing Cap Classique sector in the South African wine industry.</p>
                                    <p>At Crown to Cork, we can assist producers with services ranging from riddling and disgorgement to labelling. The wines under crown cap are riddled by our 8 Quadra’s of CMP riddling machines and disgorged by our Valentin & Thierion disgorger doser. The Cap Classique can then be labelled by our Robino & Galandrino and Cavagnino & Gatti machines.</p>
                                    <p>“Our aim is to deliver these services with a high-quality assurance.”</p>
                                    <AnchorLink offset="60" href="#contact" className="c_button">Get in touch</AnchorLink>
                                </div>
                            </Fade>
                            
                        </Grid>
                    </Container>
                </Section>
                <Section>
                    <Container id="riddling">
                        <GridSlider className="c_grid-6 c_light-background">
                            <div>
                                <img src={rid1} alt="" />
                            </div>
                            <div>
                                <img src={rid2} alt="" />
                            </div>
                            <div>
                                <img src={rid3} alt="" />
                            </div>
                        </GridSlider>
                        <Grid className="c_grid-6 c_light-background">
                            <Fade delay={350}  duration={1000}>
                                <h2 className="c_section-heading">RIDDLING</h2>
                                
                                <div className="c_copy">
                                    <p>The process of riddling has evolved tremendously. Previously done in pupitres by hand over 3 months, can now be done in automated riddling machines in as little as 24 hours.</p>

                                    <p>Once the wine has fermented and matured for its desired time, sur lie, the process of riddling begins. At Crown to Cork, the wines are packed into bottle specific cages, which are built specifically for our automated riddling machines called gyropallets. These wines are allowed to rest again for the sediment to settle. Once the skilled riddling team is happy with the sediment compaction, the cages containing the wine are riddled on their specific uniquely determined cycle. These cycles can range from 1, to 3.5 to 7-day cycles.</p>
                                </div>
                                <Horline />
                            </Fade>
                            
                        </Grid>                        
                    </Container>
                    <Container id="disgorgement">
                        <GridSlider className="c_grid-6">
                            <div>
                                <img src={dis1} alt="" />
                            </div>
                            <div>
                                <img src={dis2} alt="" />
                            </div>
                            <div>
                                <img src={dis3} alt="" />
                            </div>
                        </GridSlider>
                        <Grid className="c_grid-6 c_order-1">
                            <Fade delay={350}  duration={1000}>
                                <h2 className="c_section-heading">DISGORGEMENT</h2>

                                <div className="c_copy">
                                    <p>Disgorgement is the process where the sediment is removed from the bottle when the bottle is sur point. The wine here at Crown to Cork is stored under cooling at approximately 12˚C prior to disgorgement. These cooler conditions assist in less wine being lost as well as prevents significant loss of carbon dioxide. These bottles are then placed into a glycol bath where the necks are frozen which traps the yeast sediment.</p>

                                    <p>The bottles then move via conveyor through a neck rinsing tunnel to the disgorger where this trapped yeast plug is removed.</p>
                                </div>
                                <Horline />
                            </Fade>
                        </Grid>   
                    </Container>
                    <Container id="dosage">
                        <GridSlider className="c_grid-6 c_light-background">
                            <div>
                                <img src={dos1} alt="" />
                            </div>
                            <div>
                                <img src={dos2} alt="" />
                            </div>
                            <div>
                                <img src={dos3} alt="" />
                            </div>
                        </GridSlider>
                        <Grid className="c_grid-6 c_light-background">
                            <Fade delay={350}  duration={1000}>
                                <h2 className="c_section-heading">DOSAGE</h2>
                                
                                <div className="c_copy">
                                    <p>The dosage addition is the final stage of balancing the wine and classification determined. Classifications can range from 0g/L to over 50g/L sugar. The dosage recipe is the winemaker's special blend of wine, acidity, preservatives etc.</p>
                                </div>
                                <Horline />
                            </Fade>
                        </Grid>                        
                    </Container>
                </Section>
                <Section>
                    <Container id="contact">
                        <Grid>
                            <Fade delay={350}  duration={1000}>
                                <h2 className="c_section-heading_2">CONTACT US</h2>
                                <p>Please fill out the form below or give us a call during office hours.<br /> Someone from our team will respond promptly.</p>
                                <ContactForm />
                            </Fade>
                        </Grid>
                    </Container>
                </Section>
                
                <Footer />
                
            </>
        )
    }
}

export default Home