import React from 'react'
import Logo from './Logo'
import MobileMenu from './MobileMenu'
import WidescreenMenu from './WidescreenMenu'

class Header extends React.Component {

    state = {
        headerTransformation: false,
        headerHide: false,
        lastScrollTop: 0
    }

    componentDidMount(){
        this.handleHeaderTransformation()
        window.addEventListener('scroll', this.handleHeaderTransformation)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleHeaderTransformation)
    }

    handleHeaderTransformation = () => {

        let st = window.pageYOffset || document.documentElement.scrollTop

        if (st > this.state.lastScrollTop){
            if(!this.state.headerHide){
                this.setState({
                    headerHide: true
                })
            }
        } else {
            if(this.state.headerHide){
                this.setState({
                    headerHide: false
                })
            }
        }

        this.setState({
            lastScrollTop: st <= 0 ? 0 : st
        })

        let height = window.innerHeight - 190
        if(window.scrollY > height){
            if(!this.state.headerTransformation){
                this.setState({
                    headerTransformation: true
                })
            }
            
        } else {
            if(this.state.headerTransformation){
                this.setState({
                    headerTransformation: false
                })
            }
        }
    }

    render(){

        return(
            <header className={`c_header ${this.state.headerTransformation ? 'c_transformed' : ''} ${this.state.headerHide ? 'c_hide' : ''}`}>
                <Logo />
                <WidescreenMenu />
                <MobileMenu />
            </header>
        )
    }
}

export default Header