import React from 'react'
import {Link} from 'react-router-dom'

class Logo extends React.Component{
    render(){
        return(
            <Link to="/" className="c_logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="116.211" height="122.104" viewBox="0 0 116.211 122.104">
                    <g id="ctc-logo" transform="translate(-490.147 -209.71)">
                        <g id="Group_1" data-name="Group 1" transform="translate(496.284 209.71)">
                        <path id="Path_1" data-name="Path 1" d="M501.095,254.492l-.021-.014a4.453,4.453,0,0,1-1.3-6.3,4.522,4.522,0,0,1,3.018-2.157l.515,1.8a3.231,3.231,0,0,0-2.051,1.337,2.6,2.6,0,0,0,.9,3.618l.021.014a2.6,2.6,0,0,0,3.694-.626,3.16,3.16,0,0,0,.4-2.49l1.708-.352a4.478,4.478,0,0,1-.649,3.864A4.422,4.422,0,0,1,501.095,254.492Z" transform="translate(-498.986 -220.809)" fill="#f7f7f7"/>
                        <path id="Path_2" data-name="Path 2" d="M524.935,223.269l3.456-2.025a3.491,3.491,0,0,1,2.652-.524,2.753,2.753,0,0,1,1.661,1.348l.012.022a2.72,2.72,0,0,1-.246,3.292L535.9,227l-1.944,1.139-3.037-1.47-1.307.766,1.417,2.419-1.663.974Zm5.5,1.71a1.273,1.273,0,0,0,.648-1.816l-.013-.021c-.417-.712-1.129-.789-1.939-.314l-1.652.968,1.271,2.17Z" transform="translate(-506.918 -213.05)" fill="#f7f7f7"/>
                        <path id="Path_3" data-name="Path 3" d="M562.953,214.5v-.025a4.66,4.66,0,0,1,9.3-.494v.025a4.659,4.659,0,0,1-9.3.494Zm7.289-.367v-.025a2.663,2.663,0,0,0-2.789-2.618,2.621,2.621,0,0,0-2.489,2.859v.025a2.663,2.663,0,0,0,2.789,2.618A2.621,2.621,0,0,0,570.242,214.136Z" transform="translate(-518.537 -209.71)" fill="#f7f7f7"/>
                        <path id="Path_4" data-name="Path 4" d="M604.921,215.132l1.839.942-1.09,6.108,4.466-4.407,1.47.753-.966,6.2,4.32-4.453,1.793.919-6.685,6.491-1.493-.765.853-6-4.374,4.2-1.493-.765Z" transform="translate(-530.952 -211.367)" fill="#f7f7f7"/>
                        <path id="Path_5" data-name="Path 5" d="M641.328,244.966l.976,1.484-2.251,6.394,4.508-2.965,1.046,1.59-7.32,4.814-.9-1.37,2.321-6.605-4.652,3.061-1.046-1.59Z" transform="translate(-540.259 -220.487)" fill="#f7f7f7"/>
                        </g>
                        <g id="Group_2" data-name="Group 2" transform="translate(494.458 293.248)">
                        <path id="Path_6" data-name="Path 6" d="M502.449,333.514l-1.3-2.325,1.55-.869,3.55,6.332-1.55.869-1.3-2.325-6.092,3.415-.943-1.682Z" transform="translate(-496.356 -330.115)" fill="#f7f7f7"/>
                        <path id="Path_7" data-name="Path 7" d="M517.686,357.464l.016-.02a4.66,4.66,0,0,1,7.338,5.738l-.015.019a4.66,4.66,0,0,1-7.339-5.737Zm5.738,4.511.016-.019a2.664,2.664,0,0,0-.384-3.806,2.62,2.62,0,0,0-3.754.521l-.015.019a2.662,2.662,0,0,0,.384,3.806A2.621,2.621,0,0,0,523.424,361.975Z" transform="translate(-502.578 -337.878)" fill="#f7f7f7"/>
                        <path id="Path_8" data-name="Path 8" d="M556.229,376.4l0-.024a4.453,4.453,0,0,1,5.178-3.82,4.518,4.518,0,0,1,3.221,1.839l-1.419,1.221a3.234,3.234,0,0,0-2.073-1.3,2.6,2.6,0,0,0-2.91,2.332l0,.024a2.6,2.6,0,0,0,2.114,3.093,3.16,3.16,0,0,0,2.427-.68l1.033,1.4a4.477,4.477,0,0,1-3.781,1.026A4.423,4.423,0,0,1,556.229,376.4Z" transform="translate(-514.642 -343.008)" fill="#f7f7f7"/>
                        <path id="Path_9" data-name="Path 9" d="M588.213,376.27l-.006-.025a4.66,4.66,0,0,1,9-2.4l.006.025a4.66,4.66,0,0,1-9,2.4Zm7.058-1.863-.008-.024a2.662,2.662,0,0,0-3.27-1.986,2.621,2.621,0,0,0-1.845,3.31l.006.024a2.662,2.662,0,0,0,3.27,1.986A2.622,2.622,0,0,0,595.272,374.407Z" transform="translate(-524.385 -342.397)" fill="#f7f7f7"/>
                        <path id="Path_10" data-name="Path 10" d="M616.261,359.11l3.157-2.466a3.493,3.493,0,0,1,2.56-.872,2.751,2.751,0,0,1,1.825,1.117l.015.019a2.722,2.722,0,0,1,.193,3.3l3.613,1.148-1.776,1.387-3.206-1.054-1.194.933,1.726,2.209-1.519,1.187Zm5.678.966a1.274,1.274,0,0,0,.4-1.887l-.016-.02c-.509-.651-1.226-.632-1.964-.054l-1.51,1.18,1.549,1.983Z" transform="translate(-533.008 -337.889)" fill="#f7f7f7"/>
                        <path id="Path_11" data-name="Path 11" d="M636.823,336.808l.955-1.673,3.327,1.9-1.565-4.984,1.153-2.022,1.462,4.94,6.229-.741-1.148,2.011-4.584.511.41,1.4,2.326,1.327-.955,1.674Z" transform="translate(-539.293 -330.025)" fill="#f7f7f7"/>
                        </g>
                        <path id="Path_12" data-name="Path 12" d="M656.263,297.475a1.427,1.427,0,1,1-1.428-1.427A1.427,1.427,0,0,1,656.263,297.475Z" transform="translate(-49.905 -26.391)" fill="#f7f7f7" fill-rule="evenodd"/>
                        <path id="Path_13" data-name="Path 13" d="M490.147,297.475a1.427,1.427,0,1,0,1.428-1.427A1.427,1.427,0,0,0,490.147,297.475Z" transform="translate(0 -26.391)" fill="#f7f7f7" fill-rule="evenodd"/>
                        <g id="Group_3" data-name="Group 3" transform="translate(525.74 235.699)">
                        <path id="Path_14" data-name="Path 14" d="M586.738,247.19c-.428,0-.427.3-.73.3-.207,0-.359-.255-.644-.3a8.37,8.37,0,0,0-1.139,0,5.319,5.319,0,0,0-1.169,0c-.174.053-.171.188-.263.3-.451.564-.822-.157-1.373-.274a8.518,8.518,0,0,0-1.52-.031,5.274,5.274,0,0,0-1.52.031c-.39.138-.467.7-.876.732-.494.039-1.111-.633-1.579-.732a10.329,10.329,0,0,0-1.841-.031,6.311,6.311,0,0,0-1.754.061c-.41.142-.644.717-1.08.732-.468.016-.93-.637-1.462-.763a9.228,9.228,0,0,0-2.016-.031,10.324,10.324,0,0,0-2.016.031c-.457.123-.749.727-1.228.763-.686.05-.966-.639-1.4-.763a8.931,8.931,0,0,0-2.018-.031,8.639,8.639,0,0,0-2.045.031c-.466.12-.948.791-1.461.763-.408-.023-.617-.542-1.052-.7a6.4,6.4,0,0,0-1.841-.092,7.591,7.591,0,0,0-1.87.061c-.535.157-.986.76-1.491.7-.4-.045-.521-.619-.905-.732a6.055,6.055,0,0,0-1.52-.031,7.244,7.244,0,0,0-1.52.031c-.361.092-.678.517-.994.488-.241-.022-.365-.453-.613-.519a5.47,5.47,0,0,0-1.14,0,7.887,7.887,0,0,0-1.14,0c-.228.04-.423.3-.643.3-.278,0-.366-.285-.672-.3a.7.7,0,0,0-.789.549,16.75,16.75,0,0,1,1.49.946,5.816,5.816,0,0,1,1.082,1.372c.95,1.534,1.116,4.35,2.834,5.067a13.186,13.186,0,0,0,4.151.3h26.683a15.132,15.132,0,0,0,4.238-.244c1.918-.7,1.941-3.411,2.982-5.1a5.738,5.738,0,0,1,1.811-1.891c.157-.1.738-.312.76-.488C587.468,247.475,587.114,247.19,586.738,247.19Z" transform="translate(-541.409 -247.141)" fill="#f7f7f7" fill-rule="evenodd"/>
                        <path id="Path_15" data-name="Path 15" d="M585.328,266.194a12.224,12.224,0,0,0-2.134-2.166,5.388,5.388,0,0,0-2.659-1.586,12.27,12.27,0,0,0-2.92-.192c-.646-.013-1.339.009-2.039.009h-15.7c-1.693,0-3.476-.062-5.2-.056-1.182,0-2.348.013-3.455.086a5.415,5.415,0,0,0-2.045.488,5.36,5.36,0,0,0-1.433,1.067,12.883,12.883,0,0,0-2.454,2.563,5.533,5.533,0,0,0-.643,1.891,22,22,0,0,0-.117,2.44c-.079,1.748-.059,3.487-.059,5.094,0,3.352-.24,7.009.76,9.424a6.47,6.47,0,0,0,3.828,3.691c.589.258,1.288.5,1.52,1.006a6.461,6.461,0,0,1-.058,2.227c-.206,2.151-.478,4.39-.7,6.283-.9,7.667-1.877,15.133-2.864,22.691a5.174,5.174,0,0,0-.117,2.043c.2.349,1.116.317,1.824.289.47.037,1.046-.015,1.409-.015h30.221c.357,0,1.037.046,1.536,0a5.4,5.4,0,0,0,1.952-.127c.363-.247.1-1.439.029-2.043-.612-4.988-1.233-9.657-1.813-14.518-.564-4.732-1.35-9.743-1.782-14.548a7.9,7.9,0,0,1-.088-2.2c.16-.63,1.054-.913,1.548-1.128a6.391,6.391,0,0,0,3.479-2.959c1.023-1.926,1.139-4.478,1.139-7.595,0-3.032-.117-5.75-.117-8.661A6.2,6.2,0,0,0,585.328,266.194Z" transform="translate(-542.337 -251.745)" fill="#f7f7f7" fill-rule="evenodd"/>
                        </g>
                    </g>
                    </svg>

            </Link> 
        )
    }
}

export default Logo