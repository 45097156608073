import React from 'react'
import ContactList from './ContactList'

class WidescreenMenu extends React.Component{

    state = {
        open: false
    }

    toggleMenu = () => {
        this.setState({
            open: !this.state.open
        })
    }

    render(){
        return(
            <>  
                <nav className="c_widescreen-menu">
                    <ContactList />
                </nav>
            </>
        )
    }
}

export default WidescreenMenu