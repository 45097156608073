import React from 'react'

class Section extends React.Component{
    render(){
        return(
            <section className={`c_section ${this.props.className ? this.props.className : ''}`}>
                {this.props.children}
            </section>
        )
    }
}

export default Section