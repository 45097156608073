import React from 'react'
import HeaderV2 from '../ui/HeaderV2'
import Section from '../ui/Section'
import Container from '../ui/Container'
import Grid from '../ui/Grid'
import Footer from '../ui/Footer'

class PageNotFound extends React.Component {
    render(){
        return(
            <>
                <HeaderV2 />
                
               <Section className="c_top-section">
                    <Container>
                        <Grid>
                            <h2 className="c_section-heading">404 PAGE NOT FOUND</h2>
                            <div className="c_copy">
                                <p>The page you are looking for does not exist.</p>
                            </div>
                        </Grid>
                    </Container>
                </Section>
                <Footer />
            </>
        )
    }
}

export default PageNotFound