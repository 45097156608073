import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import WidescreenNav from './WidescreenNav'

//images
import hero1 from '../../assets/img/hero-01.jpg'
import hero2 from '../../assets/img/hero-02.jpg'
import hero3 from '../../assets/img/hero-03.jpg'

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    ><span /></div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    ><span /></div>
  );
}

export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

    renderSliderItems1(slidesData){

        return slidesData.map((item, index) => {
            return (
                <div key={index}>
                    <div className="c_hero-banner-slide" style={{
                        backgroundImage: `url(${item.img})`
                    }} />
                </div>
            )
        })
        
    }

    renderSliderItems2(slidesData){

        return slidesData.map((item, index) => {
            return (
                <div key={index}>
                    <div className="c_hero-banner-slide">
                        <div className="c_inner">
                            <div className="overlay-copy-panel">
                                <h1 className="c_hero-banner-copy c_text" dangerouslySetInnerHTML={{ __html: item.text }}></h1>
                            </div>                                                   
                        </div>
                    </div>
                </div>
            )
        })
        
    }

    

  render() {

      const slidesData = [
            {
                button: 'RIDDLING',
                img: hero1,
                text: 'Our skilled riddlers ensure your wines are perfectly clear and free from sediment'
            },
            {
                button: 'DISGORGEMENT',
                img: hero2,
                text: 'Superior automated disgorgement facilities that provide the highest-quality assurance'
            },
            {
                button: 'DOSAGE',
                img: hero3,
                text: 'We use world-class automatic labelling machines - showcasing your product beautifully'
            }
        ]

    return (
      <div className="c_hero-banner-slider">
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          dots={false}
          infinite={true}
          speed={700}
          slidesToShow={1}
          slidesToScroll={1}
          pauseOnHover={false}
          autoplay={true}
          autoplaySpeed={5000}
          arrows={false}
          swipeToSlide={true}
        >
          {this.renderSliderItems1(slidesData)}
        </Slider>
        <Slider
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          fade={true}
          slidesToShow={1}
          swipeToSlide={true}
          pauseOnHover={false}
          focusOnSelect={true}
          // dots={true}
          arrows={true}
          // dotsClass={`c_hero-banner-nav`}
          // customPaging={i => (
          //   <div className="c_item c_banner-nav-text">
          //       {slidesData[i].button}
          //   </div>
          // )}
          // responsive={[
          //     {
          //         breakpoint: 767,
          //         settings: {
          //             arrows: true
          //         }
          //     }
          // ]}
          nextArrow={<SampleNextArrow />}
          prevArrow={<SamplePrevArrow />}
        >
          {this.renderSliderItems2(slidesData)}
        </Slider>
        <WidescreenNav />
        {this.props.children}
      </div>
    );
  }
}