import React from 'react'

class Grid extends React.Component{
    render(){
        return(
            <div className={`c_grid ${this.props.className ? this.props.className : ''}`}>
                <div className="c_inner">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default Grid